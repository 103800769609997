import * as React from "react";
import constants from "../../../../constants/index";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
// @ts-ignore
import Description from "../../../texts/Description";
import Button from "../../../buttons/Button";
import Headline from "../../../texts/Headline";

const Container = Styled.div`
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    width: 68rem;
    top: 16%;
    height: calc(100% - 16%);
    padding: 5rem 3.5rem;
    text-align: center;
    
    ${Media.lessThan("900px")`
        width: 33.5rem;
        padding: 10rem 2rem 5rem 2rem;
    `}
`;

const HeadlineExt = Styled(Headline)`
  ${Media.greaterThan("900px")`
    font-size: 50px;
    line-height: 1.5;
    margin: 25px 0 3rem 0 !important;
  `}

  font-size: 22px;
  margin-top: -65px;
  color: #FFFFFF;
  line-height: 22px;
  margin: 5px 0 1.5rem 0;
`;

const ExtendedDescriptionExt = Styled(Description)`
  ${Media.greaterThan("900px")`
    max-width: 900px;
    margin-top: 142px;
    margin-bottom: 25px !important;
    font-size: 26px;
  `}

  color: #FFFFFF;
  margin: auto;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
`;

const CodeSpan = Styled(Description)`
  ${Media.greaterThan("900px")`
    font-size: 26px;
  `}

  color: #FFFFFF;
  margin: 0 9px 0 0;
  font-size: 16px;
`;

const ExtendedDescription2Ext = Styled(Description)`
  ${Media.greaterThan("900px")`
    max-width: 900px;
    font-size: 26px;
  `}

  color: #FFFFFF;
  margin: 0;
  font-size: 16px;
  font-weight: 900;
`;

const ShoutoutSignupWrapper = Styled.div`
    max-width: 43rem;
    margin: 0 auto;
    margin-top: -7;
  
  ${Media.lessThan("900px")`
      margin-top: 1.5rem;
      max-width: 100%;
  `}
`;

const Row = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${Media.greaterThan("900px")`
    margin-top: 25px;
    margin-bottom: 35px !important;
  `}
  margin-bottom: 10px;
`;

const NavButtonLink = Styled.a`
  
`;

type ComparisonLandingProps = {
  tagline: string;
  title: string;
  tagline2: string;
};

export default ({ tagline, title, tagline2 }: ComparisonLandingProps) => (
  <Container>
    <ExtendedDescriptionExt>{tagline}</ExtendedDescriptionExt>
    <HeadlineExt as="h1">{title}</HeadlineExt>

    <Row>
      <CodeSpan></CodeSpan>
      <ExtendedDescription2Ext>{tagline2}</ExtendedDescription2Ext>
    </Row>

    <ShoutoutSignupWrapper>
      <NavButtonLink href={`https://${constants.APP_DOMAIN}/auth/sign-up`}>
        <Button solid>Sign up</Button>
      </NavButtonLink>
    </ShoutoutSignupWrapper>
  </Container>
);
