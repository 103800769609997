import * as React from "react";
import Meta from "../../components/meta";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { graphql } from "gatsby";
import Layout from "../../components/layout/Layout";
import BlackFriday from "../../components/pages/black-friday/BlackFriday";

const Container = Styled.div`
  margin: 10rem 0 10rem 0;

  ${Media.greaterThan("medium")`
    margin: 12rem 0 10rem 0;
  `}
`;

const Comparison = ({ data }) => (
  <Layout>
    <Meta
      title="Uizard Black Friday Sale | 25% Off Plan Upgrades | Uizard"
      description="The Uizard Black Friday Sale is here! Get 25% of plan upgrades this Black Friday. Redeem now!"
      url="/black-friday/"
    />

    <BlackFriday
      tagline="The Uizard Black Friday sale is here!"
      title="25% off Uizard Pro with code BF25"
      tagline2=""
      imageA={data.bfLandingImageA.childImageSharp}
      imageB={data.bfLandingImageB.childImageSharp}
    />
  </Layout>
);

export default Comparison;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    bfLandingImageA: file(relativePath: { eq: "black-friday/LandingPage_Web.webp" }) {
      childImageSharp {
        fluid(maxWidth: 2200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    bfLandingImageB: file(relativePath: { eq: "black-friday/LandingPage_Mobile.webp" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
