import * as React from "react";
import Styled from "styled-components/macro";
import Image, { GatsbyImageProps } from "gatsby-image";
import Content from "../../../layout/Content/Content";
import BlackFridayLandingContent from "../../black-friday/BlackFridayLandingContent";
import MediaQuery from "../../../layout/MediaQuery";

const ImageA = Styled(Image)`
    width: calc(100%);
    box-shadow: 0px 2px 20px rgba(3, 3, 3, 0.1);
    height: 730px;
`;

const ImageB = Styled(Image)`
    width: calc(100%);
    box-shadow: 0px 2px 20px rgba(3, 3, 3, 0.1);
    height: 365px;
`;

const Container = Styled.div`
    position: relative;
    
    svg {
        max-width: 100%;

    }
`;

const ExtendedContent = Styled(Content)`
    width: 100%;
    margin-top: 5rem;
    max-width: 100%;
    background-color: #0A0A0A;
`;

type ComparisonLandingProps = {
  tagline: string;
  title: string;
  tagline2: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
};

export default ({
  imageA,
  imageB,
  tagline,
  title,
  tagline2,
}: ComparisonLandingProps) => (
  <ExtendedContent>
    <Container>
      <BlackFridayLandingContent
        tagline={tagline}
        title={title}
        tagline2={tagline2}
      />

      <MediaQuery lessThan="900px">
        <ImageB
          fluid={"fluid" in imageB ? imageB.fluid : null}
          alt="uizard black friday sale"
        />
      </MediaQuery>

      <MediaQuery greaterThan="900px">
        <ImageA
          fluid={"fluid" in imageA ? imageA.fluid : null}
          alt="uizard black friday sale"
        />
      </MediaQuery>
    </Container>
  </ExtendedContent>
);
